.secure {
  -webkit-text-security: disc;
  text-security: disc;
  &:hover {
    -webkit-text-security: revert;
    text-security: revert;
  }
}

.downshift-dropdown {
  margin: 0 auto;
  width: 20rem;
  border: 1px solid whitesmoke;
  border-bottom: none;
}
.dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid whitesmoke;
  font-size: 1rem;
  text-align: left;
}
.dropdown-button {
  padding: 0.6rem;
  border-radius: 3px;
  background: white;
  cursor: pointer;
}

// NOTE: the order of these styles DO matter
