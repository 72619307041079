.CalendarDay__selected_span {
  --tw-bg-opacity: 1;
  background-color: rgb(103, 129, 55);
  color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(103, 129, 55) !important;
  border-color: rgb(103, 129, 55) !important;
  color: white !important;
}

.SingleDatePicker_picker {
  z-index: 1000 !important;
}

.DateInput_input {
  border: none !important;
  outline: none !important;
  font-size: 19px !important;
  padding: 11px 11px 9px !important;
}

.DateInput_input__focused {
  border: none !important;
  outline: none !important;
  --tw-ring-color: inherit !important;
}

.CalendarDay__selected_span {
  background-color: rgba(103, 129, 55, 0.7) !important;
  border: 1px solid rgba(103, 129, 55, 0.9) !important;
}
CalendarDay__selected_span:active,
CalendarDay__selected_span:hover {
  background-color: rgb(103, 129, 55) !important;
  border: 1px solid rgb(103, 129, 55) !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: rgb(103, 129, 55) !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right-color: rgb(103, 129, 55) !important;
}
